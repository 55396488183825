import.meta.glob([
    '../img/**',
    '../fonts/**',
])

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm'
window.Alpine = Alpine
window.Livewire = Livewire

import Chart from 'chart.js/auto'
window.Chart = Chart

import Glide from '@glidejs/glide'
window.Glide = Glide

import masonry from 'alpinejs-masonry'
import scrollTo from 'alpinejs-scroll-to'
import focus from '@alpinejs/focus'
Alpine.plugin(masonry)
Alpine.plugin(scrollTo)
Alpine.plugin(focus)

// Alpine go
window.Livewire = Livewire
Livewire.start()
